import React from 'react';

import createSlug from '../utils/createSlug';
import formatDate from '../utils/formatDate';
import * as styles from '../components/start.module.css';
import { Helmet } from 'react-helmet';

const customerLogos = [
  require('!file-loader!../images/customers/kitchenlab.svg'),
  require('!file-loader!../images/customers/astridlindgren.svg'),
  require('!file-loader!../images/customers/caia.svg'),
  require('!file-loader!../images/customers/cervera.svg'),
  require('!file-loader!../images/customers/hm.svg'),
  require('!file-loader!../images/customers/ikea.svg'),
  require('!file-loader!../images/customers/lyko.svg'),
  require('!file-loader!../images/customers/belid.svg'),
  require('!file-loader!../images/customers/ark.svg'),
  require('!file-loader!../images/customers/vagabond.svg'),
];

const Start = ({ pageContext }) => {
  const { posts, authors } = pageContext;
  const ogLogo = require('../images/cm_text_logo.png');
  const mapImage = require('../images/map.jpg');

  const authorRows = [];
  const maxCount = 3;
  for (const author of authors) {
    if (authorRows.length === 0) {
      authorRows.push([]);
    }
    let currentRow = authorRows[authorRows.length - 1];
    if (currentRow.length === maxCount) {
      currentRow = [];
      authorRows.push(currentRow);
    }
    currentRow.push(author);
  }

  return (
    <>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap" rel="stylesheet" />
        <link
          href="https://fonts.googleapis.com/css2?family=Public+Sans:wght@400;600;900&amp;display=swap"
          rel="stylesheet"
        />
        <meta name="theme-color" content="#23184c" />
        <link rel="canonical" href="https://www.commercemind.se/" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0" />
        <meta
          name="description"
          content="Vi kan hjälpa dig med alla områden från D2C, B2B till B2C, strategisk rådgivning inom e-handel med allt
              från vilken plattform du bör välja, hur ska du jobba med leverantörer, vilken organisation du kan bygga
              upp, hur ska din tekniska arkitektur effektiviseras eller förändras osv."
        />
        <meta property="og:image" content={ogLogo} />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-7L5LC1PZ5J" />
        <script
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag() {
                dataLayer.push(arguments);
            }
            gtag('js', new Date());

            gtag('config', 'G-7L5LC1PZ5J');`,
          }}
        />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest.json" />
        <title>Commerce Mind - Vi är oberoende e-handelsexperter</title>
      </Helmet>
      <div class={styles.section} id={styles.section1}>
        <div className={styles.sectionContent}>
          <div id={styles.header}>
            <div id={styles.logo}></div>
            <div id={styles.hamburger}>
              <div className={styles.line + ' ' + styles.line1}></div>
              <div className={styles.line + ' ' + styles.line2}></div>
              <div className={styles.line + ' ' + styles.line3}></div>
            </div>
            <div id={styles.menu}>
              <ul>
                <li className={styles.active}>
                  <a href="/">Hem</a>
                </li>
                <li>
                  <a href={'#whatWeDoAnchor'}>Vad vi gör</a>
                </li>
                <li>
                  <a href={'#' + styles.whoWeAre}>Vilka vi är</a>
                </li>
                <li>
                  <a href="https://nexus.commercemind.se/" target="_blank">
                    Produkter
                  </a>
                </li>
                <li>
                  <a href="/blog">Blogg</a>
                </li>
                <li className={styles.contactButton}>
                  <a href={'#' + styles.contactUs}>Kontakta oss</a>
                </li>
              </ul>
            </div>
          </div>
          <h1 id={styles.tagline}>
            Vi är <span class={styles.independent}>oberoende</span>{' '}
            <span className={styles.nobreak}>e-handelsexperter</span>
          </h1>
          <div class={styles.logosWrapper}>
            <div class={styles.logos}>
              {customerLogos.map(l => {
                return <img src={l} alt="Logo" />;
              })}
            </div>
          </div>
        </div>
        <div id={styles.hero}></div>
      </div>
      <div class={styles.section} id={styles.blogPosts}>
        <div class={styles.sectionContent}>
          <h2>Blogg</h2>
          <div className={styles.posts}>
            {posts.slice(0, 3).map(p => (
              <div className={styles.post}>
                <h3>
                  <a href={'/blog/' + createSlug(p.node.title)}>{p.node.title}</a>
                </h3>
                <div class={styles.postLink}>
                  <p>
                    <a href={'/blog/' + createSlug(p.node.title)}>Läs inlägget</a>
                  </p>
                  <p>Publicerat {formatDate(p.node.date)}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div class={styles.section} id={styles.whatWeDo}>
        <div class={styles.sectionContent} id="whatWeDoAnchor">
          <div>
            <h2>Strategisk rådgivning inom e-handel</h2>
            <p>
              Vi kan hjälpa dig med alla områden från D2C, B2B till B2C, strategisk rådgivning inom e-handel med allt
              från vilken plattform du bör välja, hur ska du jobba med leverantörer, vilken organisation du kan bygga
              upp, hur ska din tekniska arkitektur effektiviseras eller förändras osv.
            </p>
          </div>
          <div>
            <h2>Interim- och projektuppdrag</h2>
            <p>
              Med en bred kompetens fungerar vi även i interimsroller så som projektledare, e-handelschef,
              affärsutvecklare, CTO, eller arkitekt. Mest potential får ni genom att anlita ett team på 2-4 personer.
            </p>
          </div>
        </div>
      </div>
      <div class={styles.section + ' ' + styles.customerCase + ' ' + styles.customerCaseDark}>
        <div class={styles.sectionContent}>
          <div class={styles.customerCaseText}>
            <h3>
              <span class={styles.caseHeadingPrefix}>Kundcase:</span>H&M
            </h3>
            <p>
              För H&M agerade vi som plattformsexperter, en roll som krävde omfattande kunskap om digital handel och
              förmågan att skräddarsy lösningar för en global marknadsledare. Vi arbetade även med att utveckla
              frontend-arkitektur och implementation för deras Future Commerce-initiativ, vilket innebar att skapa en
              användarupplevelse som inte bara var estetiskt tilltalande utan också funktionellt robust över olika
              marknader.
            </p>
            <p>
              Projektet omfattade även lokalisering av produktdata, en kritisk del för att säkerställa relevans och
              tillgänglighet av produkter på olika geografiska marknader. Genom detta arbete bidrog vi till att stärka
              H&M:s digitala närvaro och försäljningskapacitet, vilket placerar dem i framkanten av detaljhandelns
              digitala transformation.
            </p>
          </div>
          <div class={styles.customerCaseImage}>
            <img src={require('!file-loader!../images/cases/hm.svg')} />
          </div>
        </div>
      </div>
      <div class={styles.section + ' ' + styles.customerCase}>
        <div class={styles.sectionContent}>
          <div class={styles.customerCaseImage + ' ' + styles.large}>
            <img src={require('!file-loader!../images/cases/kjell.svg')} />
          </div>
          <div class={styles.customerCaseText}>
            <h3>
              <span class={styles.caseHeadingPrefix}>Kundcase:</span>Kjell & Company
            </h3>
            <p>
              Commerce Mind medarbetare gick in som tillfällig digital- och e-handelschef på Kjell & Company. Där ledde
              vi utvecklingen av en ny organisation och arbetssätt, vilket innebar att vi omdefinierade den digitala
              affären genom att sätta nya mål, roller och processer för långsiktig försäljningstillväxt.
            </p>
            <p>
              Detta arbete skapade en samsyn i organisationen och ett större fokus på aktiviteter som verkligen bidrog
              till måluppfyllelse i form av bruttomarginal, försäljning, trafik, konverteringsgrad, snittkvitto… Genom
              strategisk planering lyckades vi hjälpa Kjell & Company att skapa en än mer dynamisk, kommersiell och
              resultatorienterad digital försäljningsverksamhet.
            </p>
          </div>
        </div>
      </div>
      <div class={styles.section + ' ' + styles.customerCase + ' ' + styles.customerCaseDark}>
        <div class={styles.sectionContent}>
          <div class={styles.customerCaseText}>
            <h3>
              <span class={styles.caseHeadingPrefix}>Kundcase:</span>Lyko
            </h3>
            <p>
              Vi ledde utvecklingen av Lykos digitala kanaler, inklusive lanseringen av deras app, genom projektledning
              och teknisk innovation. Som arkitekter för appen och e-handeln säkerställde vi en smidig
              användarupplevelse, medan vi som interim produktägare optimerade e-handelns funktioner för att boosta
              försäljning och kundnöjdhet.
            </p>
            <p>
              Vårt arbete fortsätter med projektledning kring lagersystem för att stärka Lykos marknadsposition genom
              effektiv logistikhantering.
            </p>
          </div>
          <div class={styles.customerCaseImage}>
            <img src={require('!file-loader!../images/cases/lyko.svg')} />
          </div>
        </div>
      </div>
      <div class={styles.section + ' ' + styles.customerCase}>
        <div class={styles.sectionContent}>
          <div class={styles.customerCaseImage + ' ' + styles.large}>
            <img src={require('!file-loader!../images/cases/pierce.png')} />
          </div>
          <div class={styles.customerCaseText}>
            <h3>
              <span class={styles.caseHeadingPrefix}>Kundcase:</span>Pierce Group
            </h3>
            <p>
              När Pierce Group (24mx.se, sledstore.se, xlmoto.se) stod inför utmaningen att utvärdera och välja rätt
              e-handelsplattform för att stödja sin snabba tillväxt och expandera sitt varumärke globalt, vände de sig
              till oss på Commerce Mind för expertstöd.
            </p>
            <p>
              Genom en omfattande analys av Pierce Groups specifika behov och marknadsmål utarbetade vi en skräddarsydd
              strategi för att bedöma olika plattformsalternativ och stöttade Pierce genom hela processen att välja
              plattform.
            </p>
          </div>
        </div>
      </div>
      <div class={styles.section + ' ' + styles.customerCase + ' ' + styles.customerCaseDark}>
        <div class={styles.sectionContent}>
          <div class={styles.customerCaseText}>
            <h3>
              <span class={styles.caseHeadingPrefix}>Kundcase:</span>Astrid Lindgren
            </h3>
            <p>
              Commerce Mind har spelat en central roll i Astrid Lindgren-koncernens e-handelssatsning genom att erbjuda
              skräddarsydd strategisk rådgivning, assistans i upphandlingsprocessen och kontinuerligt stöd genom hela
              projektet. Genom ett flexibelt upplägg anpassade vi vår expertis till koncernens vision, hjälpte vi till
              att lansera en e-handelsplattform som erbjuder ett brett sortiment av licensierade produkter, vilket
              stärker banden till Astrid Lindgrens berättelser.
            </p>
            <p>
              Vårt engagemang underströk betydelsen av en väl genomtänkt digital närvaro och bidrog till en framgångsrik
              expansion på nya marknader, däribland Tyskland, genom att säkerställa en smidig övergång till e-handel som
              står på egna ben.
            </p>
            <p>
              <a
                href="https://www.commercemind.se/blog/sa-fick-astrid-lindgren-en-e-handel-i-toppklass/"
                className={styles.yellowButton}
              >
                Läs mer om vårt arbete med Astrid Lindgren
              </a>
            </p>
          </div>
          <div class={styles.customerCaseImage + ' ' + styles.large}>
            <img src={require('!file-loader!../images/cases/astridlindgren.svg')} />
          </div>
        </div>
      </div>
      <div class={styles.section + ' ' + styles.customerCase}>
        <div class={styles.sectionContent}>
          <div class={styles.customerCaseImage}>
            <img src={require('!file-loader!../images/cases/haki.png')} />
          </div>
          <div class={styles.customerCaseText}>
            <h3>
              <span class={styles.caseHeadingPrefix}>Kundcase:</span>HAKI
            </h3>
            <p>
              Commerce Mind stöttade HAKI i lanseringen av deras första e-handel genom strategisk rådgivning,
              framtagande av business case och upphandlingsstöd. Vi bidrog till skapandet av en modern e-handel
              inklusive en helt ny e-handelsplattform, vilket möjliggör för privata som företagskunder att enkelt
              beställa utvalda ställningsprodukter direkt till företaget eller byggarbetsplatsen.
            </p>
            <p>
              Detta markerar ett viktigt steg mot att tillgodose behoven hos nya kundsegment och är en del av HAKIs
              investering i framtiden för att tillhandahålla tillgängliga och flexibla lösningar.
            </p>
          </div>
        </div>
      </div>
      <div class={styles.section + ' ' + styles.customerCase + ' ' + styles.customerCaseDark}>
        <div class={styles.sectionContent}>
          <div class={styles.customerCaseText}>
            <h3>
              <span class={styles.caseHeadingPrefix}>Kundcase:</span>Cervera
            </h3>
            <p>
              Commerce Mind har under lång tid stöttat Cervera med rådgivning och praktisk hjälp i hur deras e-handel,
              kassasystem mm ska optimeras, för att säkerställa att den omnikanala affären är så effektiv som möjligt.
            </p>
            <p>
              Cervera genomgår en förändringsresa, där man förflyttar sin IT-miljö, byter ut ERP samt förändrar en rad
              integrationer mot kringliggande system. Commerce Minds produkt Nexus är numera en viktig komponent i
              detta, tillsammans med vårt personliga expertstöd i vardagen.
            </p>
            <p>
              <a href="https://nexus.commercemind.se" className={styles.yellowButton}>
                Läs mer om Nexus här
              </a>
            </p>
          </div>
          <div class={styles.customerCaseImage + ' ' + styles.large}>
            <img src={require('!file-loader!../images/cases/cervera.svg')} />
          </div>
        </div>
      </div>
      <div class={styles.section + ' ' + styles.customerCase}>
        <div class={styles.sectionContent}>
          <div class={styles.customerCaseImage}>
            <img src={require('!file-loader!../images/cases/caia.svg')} />
          </div>
          <div class={styles.customerCaseText}>
            <h3>
              <span class={styles.caseHeadingPrefix}>Kundcase:</span>CAIA
            </h3>
            <p>
              För CAIA Cosmetics bistod vi med en initial analys för att hjälpa dem att navigera i valet av
              e-handelsplattform. Genom en översiktlig utvärdering fokuserade vi på att identifiera plattformar som
              matchade deras grundläggande behov och framtidsvisioner.
            </p>
            <p>
              Vårt arbete innebar att jämföra olika alternativ baserat på kritiska funktioner och användarvänlighet,
              vilket möjliggjorde ett informerat beslut för CAIA. Resultatet la grunden för CAIAs tekniska strategi som
              stödjer deras ambitioner att växa och nå ut till fler kunder, samtidigt som det lägger en stabil grund för
              varumärkets digitala närvaro.
            </p>
          </div>
        </div>
      </div>
      <div class={styles.section} id={styles.truelyIndependent}>
        <div class={styles.sectionContent}>
          <h2>Oberoende på riktigt</h2>
          <p>
            Vi har genom åren samlat stor personlig kunskap om kund- och leverantörsutmaningar inom e-handel. Vi vill
            omsätta detta i praktiken till att vara ett oberoende expertstöd till e-handlare.
          </p>
        </div>
      </div>
      <div class={styles.section} id={styles.whoWeAre}>
        <div class={styles.sectionContent}>
          <h2>Vilka vi är</h2>
          <p className={styles.description}>
            Vi är ett team av e-handelsexperter: Strategi, teknik, arkitektur, organisation, upphandling, metodik. Och
            mer.
          </p>
          <div id={styles.team}>
            {authorRows.map(authors => (
              <ul>
                {authors.map(author => {
                  return (
                    <li>
                      <div className={styles.teamImage}>
                        <img src={author.pressImage?.localFile.childImageSharp.fixed.src} alt={author.name} />
                      </div>
                      <p className={styles.memberName}>{author.name}</p>
                      <div
                        className={styles.memberDescription}
                        dangerouslySetInnerHTML={{ __html: author.shortDescription?.childMarkdownRemark.html }}
                      />
                    </li>
                  );
                })}
              </ul>
            ))}
          </div>
          <div id={styles.joinUs}>
            <h2>Vill du bli en del av oss?</h2>
            <p>
              Är du en del av branschen idag och vill bli fri och oberoende som vi? Teknik, strategi, affärsutveckling
              är områden vi tänker på, men vet du vad, överraska oss gärna med något nytt område du är expert på!
            </p>
            <div className={styles.contactUsButton}>
              <a href="mailto:movefaster@commercemind.se" className={styles.blueButton}>
                Kontakta oss
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class={styles.section} id={styles.contactUs}>
        <div class={styles.sectionContent}>
          <h2>Kontakta oss</h2>
          <p>
            Kontakta oss på <a href="mailto:movefaster@commercemind.se">movefaster@commercemind.se</a>
          </p>
        </div>
        <div className={styles.map}>
          <a
            href="https://www.google.com/maps/place/Carlsgatan+12A,+211+20+Malm%C3%B6/@55.6112644,12.9996444,17z/"
            target="_blank"
          >
            <img src={mapImage} />
          </a>
        </div>
        <div id={styles.hq}>
          MALMö (HQ)
          <br />
          +46 703301048
          <br />
          Carlsgatan 12A
          <br />
          211 20 Malmö Sweden
        </div>
        <div className={styles.sendEmailButton}>
          <a href="mailto:movefaster@commercemind.se" className={styles.yellowButton}>
            Skicka e-post
          </a>
        </div>
      </div>
      <script
        dangerouslySetInnerHTML={{
          __html: `
        var hamburger = document.getElementById('${styles.hamburger}');
        var menu = document.getElementById('${styles.menu}');
        hamburger.addEventListener('click', function () {
        if (menu.className.indexOf('${styles.menuOpen}') === -1) {
            menu.className += ' ${styles.menuOpen}';
            hamburger.className += ' ${styles.hamburgerOpen}';
            document.body.style.overflow = 'hidden';
        } else {
            menu.className = menu.className.replace(' ${styles.menuOpen}', '');
            hamburger.className = hamburger.className.replace(' ${styles.hamburgerOpen}', '');
            document.body.style.overflow = 'auto';
        }
        });
        var menuLinks = menu.getElementsByTagName('a');
        for (var i = 0; i < menuLinks.length; i++) {
        menuLinks[i].addEventListener('click', function () {
            menu.className = menu.className.replace(' ${styles.menuOpen}', '');
            hamburger.className = hamburger.className.replace(' ${styles.hamburgerOpen}', '');
            document.body.style.overflow = 'auto';
        });
        }`,
        }}
      />
    </>
  );
};

export default Start;
